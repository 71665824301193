<template>

  <div>
    <template>
      <div id="breadcrumb-alignment" class="mb-1">
        <div class="d-flex justify-content-start breadcrumb-wrapper">
          <b-breadcrumb :items="breadCrumb()" />
        </div>
      </div>
    </template>

    <!-- Table Container Card -->

    <b-card no-body>
      <b-card-body>
        <b-row>
          <b-col cols="12" md="3" class="mb-md-0 mb-2 mobile_tab_max_width_flex mobile_tab_margin_bottom_point_five">
            <b-form-select v-model="selectedArea">
              <b-form-select-option value="" disabled>Select Area</b-form-select-option>
              <b-form-select-option :value="area._id" v-for="area in areas" :key="area._id">
                {{ area.name | capitalize }}</b-form-select-option>
            </b-form-select>
          </b-col>

          <b-col cols="12" md="3" class="mb-md-0 mb-2 mobile_tab_max_width_flex mobile_tab_margin_bottom_point_five">
            <b-form-select v-model="selectedSupplier">
              <b-form-select-option value="">Select Supplier</b-form-select-option>
              <b-form-select-option :value="supplier._id" v-for="supplier in suppliers" :key="supplier._id">
                {{ supplier.name | capitalize }}</b-form-select-option>
            </b-form-select>
          </b-col>

          <b-col cols="12" md="3" class="mb-md-0 mb-2 mobile_tab_max_width_flex mobile_tab_margin_bottom_point_five">
            <b-form-select v-model="selectedMonth">
              <b-form-select-option value="" disabled>Select Month</b-form-select-option>
              <b-form-select-option :value="month" v-for="month in months" :key="month">
                {{ month | capitalize }}</b-form-select-option>
            </b-form-select>
          </b-col>

          <b-col cols="12" md="3" class="mb-md-0 mb-2 mobile_tab_max_width_flex mobile_tab_margin_bottom_point_five">
            <b-form-select v-model="selectedDeliverySite">
              <b-form-select-option value="" >Select Deliver-To Location</b-form-select-option>
              <b-form-select-option :value="deliverySite.site_name" v-for="deliverySite in deliverySites" :key="deliverySite.site_name">
                {{ deliverySite.site_name | capitalize }}</b-form-select-option>
            </b-form-select>
          </b-col>
        </b-row>
      </b-card-body>
    </b-card>

    <b-card no-body class="mb-0" v-if="areas != null">
      <MoqReportComponent :deliverySites="deliverySites" :selectedArea="selectedArea" :selectedSupplier="selectedSupplier"
        :selectedMonth="selectedMonth" :selectedDeliverySite="selectedDeliverySite" v-if="!loading" />
    </b-card>

  </div>
</template>

<script>
import {
  BCard, BRow, BCol, BFormInput, BButton, BTable, BMedia, BAvatar, BLink, BAlert, BFormGroup, BTabs, BTab,
  BBadge, BDropdown, BDropdownItem, BPagination, BFormCheckbox, BCardBody, BFormSelectOption, BFormSelect, VBTooltip, BFormTextarea, BForm, BFormDatepicker, BBreadcrumb, BFormRadio
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import store from '@/store'
import { ref, onUnmounted } from '@vue/composition-api'
import { GET_API, POST_API } from "../../../store/actions.type"
import Datepicker from 'vuejs-datepicker';
import moment from "moment-timezone";
import VueApexCharts from 'vue-apexcharts'
import { $themeColors } from '@themeConfig'
import MoqReportComponent from './MoqReportComponent.vue'

export default {
  components: {
    Datepicker,
    BCardBody,
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BMedia,
    BAvatar,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,
    BFormCheckbox,
    vSelect,
    BFormSelect,
    BFormSelectOption,
    VueApexCharts,
    BFormTextarea,
    BAlert,
    BFormGroup,
    BForm,
    BFormDatepicker,
    BBreadcrumb,
    BFormRadio,
    MoqReportComponent,
    BTabs, BTab,
  },
  directives: {
    'b-tooltip': VBTooltip,

  },
  watch: {
    selectedArea: function (newVal, oldVal) {
      this.getAllMoq();
    },
    selectedMonth: function (newVal, oldVal) {
      this.getAllMoq();
    }
  },
  data() {
    return {
      role: this.$store.getters.currentUser.role,
      user_id: this.$store.getters.currentUser._id,
      current_user_sites: this.$store.getters.currentUser.om_sites,
      site: this.$store.getters.currentUser.om_sites[0] ? this.$store.getters.currentUser.om_sites[0] : '',
      deliverySites: '',
      organization: this.$store.getters.currentUser.organization || '',
      areas: '',
      selectedArea: '',
      selectedSupplier: '',
      moq_records: '',
      suppliers: [],
      months: ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'],
      selectedDeliverySite: '',
      selectedMonth: moment().add(1, 'months').format('MMMM'),
      user_has_sites: false,
      loading: true,
      error_message: '',
    }
  },
  methods: {
    showAlert() {
      Swal.fire({
        position: 'center',
        icon: 'error',
        text: this.error_message,
        showConfirmButton: false,
        timer: 3000
      })
    },
    getAllMoq() {
      this.suppliers = [];
      return this.$store.dispatch(POST_API, {
        data: {
          user_id: this.user_id,
          area: this.selectedArea,
          month: this.selectedMonth,
        },
        api: '/api/inventory-moq-report'
      }).then(() => {
        if (this.$store.getters.containsErrors) {
          this.error_message = this.$store.getters.getErrors;
          this.showAlert();
        } else {
          this.moq_records = this.$store.getters.getResults.data.moq_report;
          this.moq_records.forEach((data) => {
            const supplier = data.supplier;
            // Check if supplier already exists in the array
            if (!this.suppliers.some(existingSupplier => existingSupplier._id === supplier._id)) {
              this.suppliers.push(supplier);
            }
          });
          if(this.user_has_sites == false){
            this.deliverySites = this.$store.getters.getResults.data.sites;
          }
          this.loading = false;
        }
      })
    },
    findArea() {
      return this.$store.dispatch(POST_API, {
        data: {
          user_id: this.user_id,
          site: this.site,
          role: this.role,
          organization: this.organization,
        },
        api: '/api/inventory-find-area'
      }).then(() => {
        if (this.$store.getters.containsErrors) {
          this.error_message = this.$store.getters.getErrors;
          this.showAlert();
        } else {
          this.areas = this.$store.getters.getResults.data;
          this.selectedArea = this.areas[0]._id;
        }
      })
    },
    getDeliSites(){
      return this.$store.dispatch(POST_API, {
        data: {
          sites: this.current_user_sites,
        },
        api: '/api/inventory-moq-deli-sites'
      }).then(() => {
        if (this.$store.getters.containsErrors) {
          this.error_message = this.$store.getters.getErrors;
          this.showAlert();
        } else {
          this.deliverySites = this.$store.getters.getResults.data;
        }
      })
    },
    breadCrumb() {
      var item = [{
        to: { name: 'client-dashboard' },
        text: 'Dashboard',
      }, {
        to: null,
        text: 'Inventory'
      }, {
        to: null,
        text: 'Reports'
      }, {
        to: null,
        text: 'MOQ Report',
        active: true
      }];;
      return item;
    },
  },
  mounted() {
    if(this.site !== ''){
      this.getDeliSites();
      this.user_has_sites = true;
    }
    this.findArea();
  }

}
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>